<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>
        <e-col style="min-width: 45%;">
          <date-input label="Data inicial (De)" v-model="filters.data1" />
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data final (Até)" v-model="filters.data2" />
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Tipo de Data"
          >
            <erp-select
              placeholder="Qualquer"
              :options="[
                        {label: 'Conclusão da Solicitação', value: 'conclusao'},
                        {label: 'Entrada do bem', value: 'dataEntrada'},
                        {label: 'Saída do bem', value: 'dataSaida'},
                    ]"
              v-model="filters.tipoData" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Reboquista"
          >
            <reboquista-input v-model="filters.reboquista" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn @click="gerar" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps />
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel, ErpSelect
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import ReboquistaInput from "@/components/reboquista/helpers/input/ReboquistaInput"
import {datePtToEn} from "@/utils/date"
import {date} from "uloc-vue"
import {remocaoPrevisaoPagamentoReboques} from "@/domain/relatorios/services"
import {donwloadFile} from "@/utils/downloadFile"

let filters = {
  data1: null,
  data2: null,
  tipoData: 'conclusao',
  reboquista: null,
}

export default {
  name: 'RelatorioPrevPagReboquistaWindow',
  data () {
    return {
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted () {
  },
  computed: {},
  methods: {
    printViewCustom: function (data, type = 'gerencial', title = 'Relatório Gerencial', orientation = 'landscape') {
      let window = 'printView-' + data.type  + '-' + data.id
      !this.$uloc.printView.get(window) && this.$uloc.printView.new({
        wid: window,
        title: title,
        windowClass: 'erp-print',
        contentClass: '',
        props: {
          data: data,
          orientation: orientation,
          excel: () => {
            this.gerar('excel')
          }
        }
      }, () => import('@/components/relatorios/components/customPrint/DefaultPrint.vue'))
          .then((wid) => {
          }) // return wid
    },
    gerar(format) {
      let data1, data2, dataS1, dataS2
      let filters = JSON.parse(JSON.stringify(this.filters))
      this.loading = true
      if (filters.data1) {
        data1 = datePtToEn(filters.data1)
        data2 = datePtToEn(filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.data1 = data1
        filters.data2 = data2
      }

      if (filters.reboquista && filters.reboquista.id) {
        filters.reboquista = filters.reboquista.id
      }

      console.log(filters.reboquista)

      let formato = 'html'
      if (typeof format !== 'undefined' && format === 'excel') {
        formato = 'excel'
      }

      remocaoPrevisaoPagamentoReboques(filters, formato)
          .then(response => {
            this.loading = false
            if (formato === 'html') {
              this.printViewCustom(response.data, 'remocao')
            } else {
              donwloadFile(response)
            }
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  },
  components: {
    ErpSelect,
    ReboquistaInput,
    DateInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    // ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    // ErpSelect
  }
}
</script>
